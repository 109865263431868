import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "payday-info" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

import frequencyTypes from '@/frequencyTypes';
import nthFormatter from '@/nth-formatter';
import weekdays from '@/weekdays';
import { Payday } from '@/types/Payday';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaydayForm',
  props: {
    modelValue: {},
    frequencyId: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isFormVisible = ref(false);

const paydayName = computed(() => {
  return format(props.frequencyId, props.modelValue);
});

const paydays = computed(() => {
  return getPayDays(props.frequencyId);
});

const getPayDays = (frequencyId: string) => {
  if (frequencyId === frequencyTypes.weekly) return getWeeklyPaydays(frequencyId);
  return getMonthlyPaydays(frequencyId);
};

const getWeeklyPaydays = (frequencyId: string) => {
  return getPaydaysArray(frequencyId, 7);
};

const getMonthlyPaydays = (frequencyId: string) => {
  return getPaydaysArray(frequencyId, 31);
};

const getPaydaysArray = (frequencyId: string, upperBound: number): Payday[] => {
  const paydays: Payday[] = [];

  for (let i = 1; i <= upperBound; i++) {
    paydays.push({ id: i, name: format(frequencyId, i) });
  }
  return paydays;
};

const format = (frequencyId: string, payday: number) => {
  if (frequencyId === frequencyTypes.weekly) return weekdays.getName(payday);
  return nthFormatter.format(payday);
};

const open = (): void => {
  isFormVisible.value = true;
};

const close = (): void => {
  isFormVisible.value = false;
};

const updateValue = (event: Event) => {
  close();
  const value = parseInt((event.target as HTMLInputElement).value);
  emit('update:modelValue', value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Payday", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("p", null, [
        _createElementVNode("select", {
          value: _ctx.modelValue,
          onInput: updateValue
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paydays.value, (p) => {
            return (_openBlock(), _createElementBlock("option", {
              value: p.id,
              key: p.id
            }, _toDisplayString(p.name), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
        _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(close, ["prevent"])
        }, "Cancel")
      ], 512 /* NEED_PATCH */), [
        [_vShow, isFormVisible.value]
      ]),
      _withDirectives(_createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(paydayName.value) + " ", 1 /* TEXT */),
        _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(open, ["prevent"])
        }, "Change")
      ], 512 /* NEED_PATCH */), [
        [_vShow, !isFormVisible.value]
      ])
    ])
  ]))
}
}

})