import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "frequency-info" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

import { Frequency } from '@/types/Frequency';
    import { computed, ref } from 'vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'FrequencyForm',
  props: {
    modelValue: {},
    frequencies: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

    const props = __props;

    const emit = __emit;

    const isFormVisible = ref(false);

    const frequencyName = computed(() => {
        for (const c of props.frequencies) {
            if (c.id === props.modelValue) {
                return c.name;
            }
        }
        return '';
    });

    const open = (): void => {
        isFormVisible.value = true;
    };

    const close = (): void => {
        isFormVisible.value = false;
    };

    const updateValue = (event: Event) => {
        close();
        const value = (event.target as HTMLInputElement).value;
        emit('update:modelValue', value);
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Frequency", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("p", null, [
        _createElementVNode("select", {
          value: _ctx.modelValue,
          onInput: updateValue
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencies, (f) => {
            return (_openBlock(), _createElementBlock("option", {
              value: f.id,
              key: f.id
            }, _toDisplayString(f.name), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
        _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(close, ["prevent"])
        }, "Cancel")
      ], 512 /* NEED_PATCH */), [
        [_vShow, isFormVisible.value]
      ]),
      _withDirectives(_createElementVNode("p", null, [
        _createTextVNode(_toDisplayString(frequencyName.value) + " ", 1 /* TEXT */),
        _createElementVNode("a", {
          href: "#",
          onClick: _withModifiers(open, ["prevent"])
        }, "Change")
      ], 512 /* NEED_PATCH */), [
        [_vShow, !isFormVisible.value]
      ])
    ])
  ]))
}
}

})