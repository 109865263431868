import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "message" }
const _hoisted_3 = {
  key: 0,
  class: "next-payday"
}
const _hoisted_4 = {
  key: 1,
  class: "error"
}
const _hoisted_5 = { class: "settings" }
const _hoisted_6 = { class: "footer" }
const _hoisted_7 = { class: "contact" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]

import dayjs from 'dayjs';
import CountryForm from './components/CountryForm.vue';
import TimezoneForm from './components/TimezoneForm.vue';
import FrequencyForm from './components/FrequencyForm.vue';
import PaydayForm from './components/PaydayForm.vue';
import { Country } from '@/types/Country';
import { Frequency } from '@/types/Frequency';
import { Timezone } from '@/types/Timezone';
import ajax from './ajax';
import urls from './urls';
import defaults from './defaults';
import storage from './storage';
import frequencyTypes from './frequencyTypes';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { computed, onMounted, ref, watch } from 'vue';
import { def } from '@vue/shared';

const apiHost = 'api.isitpayday.com';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

dayjs.extend(advancedFormat);

const error = ref('');
const isOptionsReady = ref(false);
const isPaydayReady = ref(false);
const isPayday = ref(false);
const nextPayday = ref<Date | null>(null);
const localTime = ref('');
const payday = ref(defaults.payday);
const timezone = ref(defaults.getDefaultTimezone());
const frequency = ref(defaults.frequency);
const country = ref(defaults.country);
const countries = ref<Country[]>([]);
const timezones = ref<Timezone[]>([]);
const frequencies = ref<Frequency[]>([]);
const email = ref('info@isitpayday.com');

const isReady = computed(() => {
  return isPaydayReady.value && isOptionsReady.value;
});

const message = computed(() => {
  if (error.value) return 'Error';
  return isPayday.value ? 'YES!!1!' : 'No =(';
});

const nextPaydayMessage = computed(() => {
  if (nextPayday.value === null) return '';

  var formattedDate = dayjs(nextPayday.value).format('MMM D');
  return `Next payday is ${formattedDate}`;
});

const formattedLocalTime = computed(() => {
  if (localTime.value) return dayjs(localTime.value).format('MMM D YYYY, HH:mm:ss');
  return '';
});

const hasError = computed(() => {
  return !!error.value;
});

const mailtoUrl = computed(() => {
  return `mailto:${email.value}`;
});

const apiUrl = computed(() => {
  return `https://${apiHost}`;
});

watch(country, (oldVal, newVal) => {
  storage.saveCountry(country.value);
  loadPayday();
});

watch(frequency, (oldVal, newVal) => {
  storage.saveFrequency(frequency.value);
  payday.value = frequency.value === frequencyTypes.weekly ? defaults.weeklyPayday : defaults.monthlyPayday;
  storage.savePayday(payday.value);
  loadPayday();
});

watch(timezone, (oldVal, newVal) => {
  storage.saveTimezone(timezone.value);
  loadPayday();
});

watch(payday, (oldVal, newVal) => {
  storage.savePayday(payday.value);
  loadPayday();
});

watch(isOptionsReady, (oldVal, newVal) => {
  const isSavedTimezoneValid = !!timezones.value.find((tz) => tz.id === timezone.value);

  if (!isSavedTimezoneValid) {
    timezone.value = defaults.getDefaultTimezone();
    storage.saveTimezone(timezone.value);
  }
});

const loadSettings = () => {
  country.value = storage.getCountry();
  frequency.value = storage.getFrequency();
  timezone.value = storage.getTimezone();
  payday.value = storage.getPayday();
};

const loadPayday = async () => {
  try {
    const response = await ajax.get(paydayUrl.value);
    isPayday.value = response.data.isPayDay;
    nextPayday.value = new Date(response.data.nextPayDay);
    localTime.value = response.data.localTime;
    isPaydayReady.value = true;
  } catch (e) {
    error.value = 'Error loading payday';
  }
};

const loadOptions = async () => {
  try {
    const [countriesResponse, frequenciesResponse] = await Promise.all([
      ajax.get(urls.countriesUrl),
      ajax.get(urls.frequenciesUrl),
    ]);
    countries.value = countriesResponse.data;
    frequencies.value = frequenciesResponse.data;
    timezones.value = getTimezones();
    isOptionsReady.value = true;
  } catch (e) {
    error.value = 'Error loading options';
  }
};

const getTimezones = () => {
  const ids = (Intl as any).supportedValuesOf('timeZone') as string[];
  return ids.map((id: string) => {
    return {
      id: id,
      name: id,
    };
  });
};

const paydayUrl = computed(() => {
  return frequency.value === frequencyTypes.weekly
    ? urls.weeklyUrl(payday.value, timezone.value, country.value)
    : urls.monthlyUrl(payday.value, timezone.value, country.value);
});

onMounted(async () => {
  loadSettings();
  await Promise.all([loadPayday(), loadOptions()]);
});

return (_ctx: any,_cache: any) => {
  return (isReady.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(message.value), 1 /* TEXT */)
        ]),
        (!isPayday.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(nextPaydayMessage.value), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (hasError.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(error.value), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Settings", -1 /* HOISTED */)),
          _createVNode(FrequencyForm, {
            modelValue: frequency.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((frequency).value = $event)),
            frequencies: frequencies.value
          }, null, 8 /* PROPS */, ["modelValue", "frequencies"]),
          _createVNode(PaydayForm, {
            modelValue: payday.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((payday).value = $event)),
            frequencyId: frequency.value
          }, null, 8 /* PROPS */, ["modelValue", "frequencyId"]),
          _createVNode(CountryForm, {
            modelValue: country.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((country).value = $event)),
            countries: countries.value
          }, null, 8 /* PROPS */, ["modelValue", "countries"]),
          _createVNode(TimezoneForm, {
            modelValue: timezone.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((timezone).value = $event)),
            timezones: timezones.value
          }, null, 8 /* PROPS */, ["modelValue", "timezones"])
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(formattedLocalTime.value), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_7, [
          _cache[5] || (_cache[5] = _createTextVNode(" Api: ")),
          _createElementVNode("a", { href: apiUrl.value }, _toDisplayString(apiHost), 8 /* PROPS */, _hoisted_8),
          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
          _cache[7] || (_cache[7] = _createTextVNode(" Bugs and suggestions: ")),
          _createElementVNode("a", { href: mailtoUrl.value }, _toDisplayString(email.value), 9 /* TEXT, PROPS */, _hoisted_9)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}
}

})